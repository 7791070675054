@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}
/* 
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  background: #f3f4f6;

}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.pagination {
  margin-top: 10px;
}

.pagination span {
  cursor: pointer;
  margin-right: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination span:hover {
  background-color: #e6e6e6;
}

.update-form {
  background-color: #e9ebec;
  /* border: 1px solid #bec0c1; */
  padding: 15px;
  margin-top: 10px;
  border-radius: .375rem;
}

/* tr:nth-child(even) {
  background-color: #ffffff;
} */

.table_back {
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

td {
  background-color: #ffffff;
  padding: 8px;
  font-size: 14px;
  font-family: Jost, sans-serif;
  font-weight: 400;
  padding: 15px;
  text-align: center; /* Center align text */
}


.heading{
  font-size:18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

th {
  background-color: #f7f8f9;
  color: #333;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  padding: 15px;
  cursor: pointer; /* Add cursor pointer for sortable columns */
  font-size: 15px;
  text-transform: capitalize;
  text-align: center; /* Center align text */
}

th:first-child {
  border-top-left-radius: 20px;
}

th:last-child {
  border-top-right-radius: 20px;
}


th,
td {

  width: auto; /* Reset width */
  max-width: 300px; /*Set maximum width for all cells */
  white-space: nowrap; /*Prevent text wrapping */
  overflow: hidden; /* Hide overflowing content*/
  text-overflow: ellipsis; /* Show ellipsis for overflowing content */
}


body {
  margin: 0;
  padding: 0;
  font-family: Jost, sans-serif;
  
}

.container {
  display: flex;
  /* flex-direction: column; */
  height: 100vh; /* Set height to 100% of the viewport */
}

.panel {
  width: 20%; /* Adjust the width as needed */
  background-color: #f0f0f0; /* Light background color */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.tables {
  flex: 1;
   /* Allow the tables to take the remaining space */
  padding: 20px;
  background-color: white;
  border-radius: .375rem;
}

.tables {
  overflow-x: auto;
}

.search-icon {
  display: inline-block;
  width: 100%;
  text-align:center;

  
}
.top-bar {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: black;
}

.main-content {
  flex: 1;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  /* overflow-x: auto; /* Add horizontal scrolling */
  /* max-width: 100%; Limit the width of the main content */ 
}

.content {
  display: flex;
  justify-content: space-between;
}

.left-bar {
  font-size: 14px;
  font-weight: 500;
  width: 20%;
  background-color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
}

.left-bar.collapsed {
  width: 80px;
}

.left-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.left-bar li {
  cursor: pointer;
  padding: 10px;
  border-radius: 0px 15px 15px 0px;
  /* border-bottom: 1px solid #ddd; */
  display: flex;
  align-items: center;
  transition:  color 0.9s ease;

}

.left-bar li .icon {
  font-weight: 500;
  color: #aaaaaa;
  margin-right: 10px; /* Adjust this value as needed */
  display: inline-block; /* Ensures the icon behaves like an inline element */
  width: 20px; /* Adjust width if needed */

  line-height: 20px; /* Adjust line height if needed */
  transition:  color 0.9s ease;
}

.selected-tab {
  background-color: #f8f7f7;
  color: #fca314;
}

.selected-tab-icon {
  background-color: #f8f7f7;
  color: #fca314;
}


a {
  color: #787777;
  text-decoration: none; /* Remove underline if needed */
}



.left-bar li:hover {
  background-color: #f8f7f7;
  color: #fca314;

}

.left-bar li:hover .icon {
  color: #fca314; /* Change icon color on hover */
}


.left-bar.collapsed li:not(:hover) {
  span:not(.icon) {
    display: none;
  }}



.update-form {
  display: flex;
  flex-wrap: wrap;
}

.form-group {
  margin-right: 20px;
  margin-bottom: 10px;

}

.form-fields{
  border-radius: 15px;
  color:black;
  padding: 8px;
  border-width: 0px;
  font-size: 14px;
}

.form-labels{
  padding: 10px;
  color: black;
  text-transform: capitalize;
  font-size: 15px;
}

.field-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.select-dropdown {
  margin-left: 10px; /* Adjust the margin between label and select */
}

.add-invoice {

  /* background-color: #e9ebec; */
  /* border: 1px solid #bec0c1; */
  padding: 15px;
  margin-top: 10px;
  border-radius: .375rem;
  display: flex;
  
}


.add-invoice-button {

  margin-left: auto; 
  margin-right: 0;
  margin-bottom: 10px;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;

  font-size: 14px;
  font-weight: 600;
  /* margin-top: 5px; */
  cursor: pointer;
  border-radius: 8px;
  Background-color: #fca314;
}

label {
  margin-right: 5px;
  font-weight: bold;
}

.icon-search{

  display: inline-block;
  color: #aaaaaa;
  font-size: 50%;
}

.update-button {
  /* background-color: #4caf50; */
  color: #4caf50;
  border: none;
  padding: 10px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin-top: 5px; */
  margin: 5px;
  cursor: pointer;
  border-radius: 15px;
}


.close-button {
  /* background-color:; */
  color:  #ea3f30;
  border: none;
  padding: 10x 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.update-button:hover {
  color: #009805;
}

.close-button:hover {
  color: #dc1200;
}


.action-button {
    color: #aaaaaa;;
  border: none;
  padding: .5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-style: italic;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.action-button:hover {
  color: black;
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 300px; /* Adjust the width as needed */
}

.login-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.login-content div {
  margin-bottom: 15px;
}

.login-content label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.login-content input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-content button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
}

.login-content button:hover {
  background-color: #2980b9;
}

.login-content p {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.logo {
  margin-left: 50px;
  width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;

}

.user {
  margin-right: 20px;
}

.collapse-btn {
  cursor: pointer;
  padding: 10px 20px 20px 10px;
  text-align: right;

  /* background-color: #ddd; */
  /* border-top: 1px solid #ccc; */
}

.icon-collapse{

  display: inline-block;


}

.page-numbers {
  display: flex;
  margin-top: 10px;
}

.page-number {
  font-weight: 700;
  cursor: pointer;
  margin-right: 5px;
  padding: 5px 12px;
  /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
  border-width: 0.5px;
  border-radius: 3px;
  color: #333333;
  background-color: white;
  transition:  color 0.9s ease;
}

.selected {
  background-color: #fca314;
  color: #ffffff;
}

.page-number:hover {
  background-color: #f8f7f7;
  color: #fca314;
}

/* Main container for the pop-up */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other content */
}

/* Content inside the pop-up */
.popup-content {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  width: 400px; /* Adjust width as needed */
  max-width: 90%; /* Ensure it fits within smaller screens */
  position: relative; /* For positioning the close button */
}

/* Close button inside the pop-up */
.popup-content .close-button {
  background: transparent;
  border: none;
  color: #aaa;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popup-content .close-button:hover {
  color: #000;
}

/* Styling for textarea and buttons inside the pop-up */
.popup-content textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
}

.popup-content button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #2980b9;
}

.popup-content button:last-child {
  background-color: #e74c3c;
}

.popup-content button:last-child:hover {
  background-color: #c0392b;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.loading-spinner img {
  width: 100%;
  height: 100%;
}

/* .collapse-btn:hover {
  background-color: #bbb;
} */

.dashboard-section {
  padding: 20px;
  background-color: white;
  border-radius: .375rem; /* Matches the border radius used in your tables and forms */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow similar to other elements */
  margin-bottom: 20px; /* Adds space below the section */
}

.dashboard-section h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px; /* Space between heading and content */
  color: #333; /* Same as the color used for table headers */
  text-align: center; /* Align heading text to the center */
}

.dashboard-section p {
  font-size: 14px;
  color: #555; /* Lighter shade for body text */
  line-height: 1.5;
}

.dashboard-section .section-content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between content elements */
}

.toggle-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px; /* Matches your font size style */
  font-weight: 500; /* Consistent weight for labels */
  color: #555; /* Muted text color to match form elements */
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 25px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6e6e6; /* Light background for inactive state */
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
  border-radius: 15px; /* Matches your rounded corners design */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.toggle-box{
  padding:15px;
}

.slider::before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: #ffffff; /* White toggle knob */
  transition: transform 0.4s ease, background-color 0.4s ease;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Adds depth */
}

input:checked + .slider {
  background-color: #fca314; /* Matches your accent color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on active */
}

input:checked + .slider::before {
  transform: translateX(25px);
  background-color: #ffffff; /* Keeps knob consistent */
}
